import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import he from 'he';

const HeadMeta = ({ title, description, image, url: pageUrl }) => {
  const router = useRouter();

  const url = pageUrl || NEXT_APP_ROOT_URL + router.asPath;
  const twitterSite = '@mcrocklin';
  const twitterUsername = '@mcrocklin';
  var strippedDesc = description && description.replace(/<[^>]+>/g, '');

  return (
    <Head>
      {title && <title>{he.decode(title)}</title>}
      <meta property="og:type" content="website" />
      {title && <meta property="og:title" content={title} />}
      {description && (
        <>
          <meta property="og:description" content={strippedDesc} />
          <meta name="Description" content={strippedDesc} />
        </>
      )}
      {url && <meta property="og:url" content={url} />}
      {image && <meta property="og:image" content={image} />}
      {twitterSite && <meta name="twitter:card" content="summary" />}
      {twitterSite && <meta name="twitter:site" content={twitterSite} />}
      {twitterUsername && <meta name="twitter:creator" content={twitterUsername} />}
      {title && <meta name="twitter:title" content={title} />}
      {description && <meta name="twitter:description" content={strippedDesc} />}
      {image && <meta name="twitter:image" content={image} />}
    </Head>
  );
};

export default HeadMeta;
